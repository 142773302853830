import { render, staticRenderFns } from "./product-with-content.vue?vue&type=template&id=f88adcbe"
import script from "./product-with-content.vue?vue&type=script&lang=js"
export * from "./product-with-content.vue?vue&type=script&lang=js"
import style0 from "./product-with-content.vue?vue&type=style&index=0&id=f88adcbe&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VImg,VRow})
