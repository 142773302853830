<template>
	<div class="flavour-types">

		<transition-group name="drop" tag="ul" class="h5 flavour-types__list" :class="{h6:!isLG,empty:!typeList}">
			<li v-for="(type,index) of typeList" :key="'type'+index"
				@click="onTypeClick(index)"
				:class="{active:typeIndex===index}">{{ type.text }}
			</li>
		</transition-group>

		<flavour-list-mini :items="items"
						   :swipable="swipable"
						   :selectable="selectable"
						   class="mt-4 mt-sm-8 mt-lg-10"
						   @loaded="onListLoaded"
						   @click="onItemClick"/>
	</div>
</template>

<script>
import FlavourListMini from "@/components/flavour-list-mini";
import vp from "@/mixins/vp";

export default {
	name: "flavour-types",
	components: {
		FlavourListMini,
	},
	props: ["flavours", "types", "swipable", "selectable", "offers"],
	mixins: [vp],
	data() {
		return {
			typeIndex: 0
		}
	},
	computed: {
		page() {
			return this.$store.state.page.ProductController;
		},
		product() {
			return this.page.item;
		},
		items() {
			// make flavours unique
			if (!this.offers && !this.typeList && this.product.BOX_SIZE) {
				const uniqueFlavoursWithCount = [];
				this.flavours.forEach(flavour => {
					const found = uniqueFlavoursWithCount.find(el => Number(el.ID) === Number(flavour.ID));
					if (found) found.count++;
					else uniqueFlavoursWithCount.push({
						...flavour,
						count: 1
					});
				})

				return uniqueFlavoursWithCount;
			}

			if (this.offers) {
				// предложения всегда имеют четкий состав
				const flavours = [];
				const offer = this.offers[this.typeIndex];
				if (!offer?.CONTENT_JSON) return this.flavours;
				//console.log("offer?.CONTENT_JSON", offer?.CONTENT_JSON);
				offer?.CONTENT_JSON?.flavours?.forEach(el => {
					const id = typeof el === "object" ? Number(el.id) : Number(el);
					const f = this.flavours?.find(fl => Number(fl.ID) === Number(id));
					if (f) flavours.push(f);
				});
				return flavours;

			}

			if (this.flavours && !this.typeList) {
				// предложения всегда имеют четкий состав
				const flavours = [];
				const offer = this.flavours[this.typeIndex];
				if (!offer?.CONTENT_JSON) return this.flavours;
				//console.log("offer?.CONTENT_JSON", offer?.CONTENT_JSON);
				offer?.CONTENT_JSON?.flavours?.forEach(el => {
					const id = typeof el === "object" ? Number(el.id) : Number(el);
					const f = this.flavours?.find(fl => Number(fl.ID) === Number(id));
					if (f) flavours.push(f);
				});
				return flavours;

			}

			// только вкусы соотв. типа / оффера
			if (this.typeList) {
				if (!this.typeList[this.typeIndex].value) return this.flavours;
				return this.flavours?.filter(el => el.TYPES && Object.keys(el.TYPES)?.some(id => Number(id) === Number(this.typeList[this.typeIndex].value)));
			}



		},
		typeList() {

			// 1. could be offers
			if (this.offers) {
				return this.offers.map(el => ({value: el.ID, text: this.isSM ? el.SHORT : (el.SHORT_XS || el.SHORT)}));
			}

			// 2. could be flavour types
			if (!this.types) return null;
			const types = [{
				value: 0,
				text: 'Все',
			}, ...Object.keys(this.types).map(key => ({value: this.types[key], text: key}))];

			return types.filter(el => !el.value || this.flavours.find(f => f.TYPES && Object.keys(f.TYPES).some(id => id === el.value)));
		},
	},
	methods: {
		onTypeClick(index) {
			this.typeIndex = index;
			if (this.offers) this.$emit("offer", this.offers[index], index);
		},
		onListLoaded() {
			console.log('flavours', this.flavours);
		},
		onItemClick(item) {
			this.$emit("click", item);
		},
	},
	mounted() {

	}
}
</script>

<style lang="scss">
.flavour-types {

	&__list {
		//margin: 30px 0 0 0 !important;;
		padding: 0 !important;
		list-style: none;
		border-bottom: 1px solid $golden-light;
		display: flex;
		justify-content: center;
		height: 32px;
		margin-top: 0 !important;
		//justify-content: flex-start;
		@include transition();

		@include up($sm) {
			height: 48px;
		}

		&.empty {
			height: 1px;
		}

		li {
			margin: 0;
			padding: 12px 24px 12px 24px !important;
			cursor: pointer;
			color: $black;
			border: 1px solid transparent;
			border-radius: 3px;
			position: relative;
			//top: 2px;
			top: 1px;
			flex: 1;
			//height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			@include transition();

			@include up($sm) {
				flex: unset;
			}

			&:not(.active):hover {
				opacity: 0.6;
				color: $golden;
			}

			&.active {
				position: relative;
				color: $golden;
				//background: $gray-lightest;
				//background: $white;
				border: 1px solid $golden-light;
				//border-top: 1px solid $golden-light;
				//border-bottom: 1px solid $golden-light;
				border-bottom-color: $gray-lightest;

				@include up($md) {
					border-bottom-color: $white;
				}

				/*&::after {
					content: '';
					position: absolute;
					bottom: -5px;
					left: 0;
					width: 100%;
					height: 10px;
					background: $white-alpha;
					filter: blur(2.5px);
				}*/

				/*@include up($sm) {
					margin: unset;
				}*/
			}

			& + li {
				margin-left: 4px;
			}
		}
	}

	&__offers {
		margin-top: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid $golden-light;
	}

	&__offers-list {
		display: flex;
		gap: 12px;
		justify-content: center;
	}

	&__offer {
		display: flex;
		width: 78px;
		height: 78px;
		border: 2px solid transparent;
		border-radius: 50%;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		@include transition();


		span, .v-image {
			max-width: 64px;
			width: 64px;
			height: 64px;
			border-radius: 50%;
			background: $golden-light;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $black;
			text-align: center;
			font-size: $fs-2;
			//letter-spacing: $ls-3;
			//text-transform: uppercase;
			padding: 10px;
			line-height: 100%;
		}

		&.--active {
			border: 2px solid $golden;
		}

		&:hover {
			//border: 2px solid $golden;
			opacity: .7;
		}
	}
}

</style>